import { createStore } from "vuex";

export default createStore({

    state: {
        showUserCenterPopup: false,
        showHtmlDialog: false,
        htmlDialogType: 0,
        showLoadingDialog: false,
        loadingMessage: "Loading...",
        data: {
            "id": 593,
            "identity": "facai888",
            "gender": 0,
            "phone": "12312123132",
            "invite": "MNptxa",
            "pid": 0,
            "head": "/static/images/head.png?v=1.5",
            "last_time": 1724509931,
            "level": 1,
            "ip": "45.78.31.155",
            "isp": "香港葵青區大窝口村 - xTom Hong Kong Limited",
            "balance": "10153.15",
            "income": "143.15",
            "income_subtotal": "143.15",
            "freeze": "0.00",
            "withdraw": "0.00",
            "recharge": "10000.00",
            "status": 1,
            "credit": 100,
            "reg_datetime": "2024-08-08 10:03:10",
            "member_type": 1,
            "lang": "en",
            "money_symbol": "$",
            "copyright": "Copyright © 2023 tinyclues Labs, Inc All rights reserved.",
            "app_name": "tinyclues",
            "app_status": 0,
            "grab_begin_time": "11:00",
            "grab_end_time": "23:00",
            "today_grab_total": 5,
            "today_grab_total_no_status": 6,
            "today_grab_round": 0,
            "today_profit": "0.00",
            "total_completed": 5,
            "total_assets": "10153.15",
            "total_profit": 143.15,
            "checked": 0,
            "level_title": "VIP1",
            "level_ico": "/uploads/product/20240508/52a942f824de86150c87d669c465a0e3.png",
            "grab_max": 40,
            "level_arr": [{
                    "id": 1,
                    "level": 1,
                    "title": "VIP1",
                    "ico": "product/20240508/52a942f824de86150c87d669c465a0e3.png",
                    "reward_rate": "0.70",
                    "reward_rate_lian": "4.90",
                    "grab_max": 40,
                    "withdraw_count": 5,
                    "withdraw_fee": "0.00",
                    "withdraw_min": "50.00",
                    "withdraw_max": "5000.00",
                    "intro": "<p><strong>Bronze Member</strong></p><p>● Deposit 100USDT to get VIP1</p><p>● Earn 0.7% for every 1 task completed and 4.9% for Combo Tasks completed</p><p>● A group of 40 tasks</p><p><span style=\"text-wrap: nowrap;\"></span><br/></p>",
                    "level_ico": "/uploads/product/20240508/52a942f824de86150c87d669c465a0e3.png"
                },
                {
                    "id": 2,
                    "level": 2,
                    "title": "VIP2",
                    "ico": "product/20240509/9c81ca57c59b24f9841653c459b3cd22.png",
                    "reward_rate": "1.00",
                    "reward_rate_lian": "7.00",
                    "grab_max": 50,
                    "withdraw_count": 5,
                    "withdraw_fee": "0.00",
                    "withdraw_min": "200.00",
                    "withdraw_max": "20000.00",
                    "intro": "<p><strong>Silver Member</strong></p><p>● Deposit 1500USDT and get VIP2</p><p>●&nbsp;Earn 1.0% for every 1 task completed and 7.0% for completing Combo Tasks.</p><p>● A group of 50 tasks</p><p><br/></p>",
                    "level_ico": "/uploads/product/20240509/9c81ca57c59b24f9841653c459b3cd22.png"
                },
                {
                    "id": 3,
                    "level": 3,
                    "title": "VIP3",
                    "ico": "product/20240509/9f01a000fa8d2cf3a128eed4af298caf.png",
                    "reward_rate": "1.20",
                    "reward_rate_lian": "6.00",
                    "grab_max": 55,
                    "withdraw_count": 5,
                    "withdraw_fee": "0.00",
                    "withdraw_min": "500.00",
                    "withdraw_max": "50000.00",
                    "intro": "<p><strong>Gold Member</strong></p><p>&nbsp;● Deposit 5000USDT and get VIP3</p><p>&nbsp;● Earn 1.2% for every 1 task completed and 6.0% for Combo Tasks completed</p><p>&nbsp;● A group of 55 tasks</p>",
                    "level_ico": "/uploads/product/20240509/9f01a000fa8d2cf3a128eed4af298caf.png"
                },
                {
                    "id": 4,
                    "level": 4,
                    "title": "VIP4",
                    "ico": "product/20240508/0a6531cc0b01c357653c7f4b2e3304a8.png",
                    "reward_rate": "1.50",
                    "reward_rate_lian": "7.50",
                    "grab_max": 60,
                    "withdraw_count": 99,
                    "withdraw_fee": "0.00",
                    "withdraw_min": "5000.00",
                    "withdraw_max": "99999999.00",
                    "intro": "<p><strong>Diamond Member<br/></strong></p><p>● Deposit 10000USDT and get VIP4</p><p>● Earn 1.5% for every 1 task completed and 7.5% for Combo Tasks completed</p><p>● A group of 60 tasks</p>",
                    "level_ico": "/uploads/product/20240508/0a6531cc0b01c357653c7f4b2e3304a8.png"
                }
            ],
            "scroll_notice": {
                "title": "Dear user, your account amount has changed."
            },
            "progress_scale": [
                0,
                5,
                10,
                15,
                20,
                25,
                30,
                35,
                40
            ],
            "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhZG1pbiIsImlhdCI6MTcyNDUwOTkzMiwidWlkIjo1OTMsImlkZW50aXR5IjoiZmFjYWk4ODgiLCJzdGF0dXMiOjF9.MpJnQugctUB-VcoQ9lsjdXRuQYYhjLa_s6AH8AsMAns",
            "poster_url": "/uploads/20240125/258ab7951b69e15b29090cdaabdbb97f.jpg?v1.3"
        },
        records: [
        {
            "id": 51024,
            "uid": 593,
            "product_id": 418,
            "product_title": "Daily Workouts",
            "product_photo": "/uploads/product/20231115/zHqPgCW3iBfuKfPiClFR3il472GczgFlx3VIiz-8WFSxOVVG_Vw2IAV6knEKZkYabxfZ=s256.jpg",
            "identity": "facai888",
            "category": 1,
            "union_id": null,
            "amount": "3278.00",
            "profit": "22.95",
            "status": 0,
            "union_status": 0,
            "reset": 0,
            "reset_label": null,
            "create_time": "2024-08-17 08:54:23",
            "money_symbol": "$"
        },
        {
            "id": 51023,
            "uid": 593,
            "product_id": 197,
            "product_title": "Tonton",
            "product_photo": "/uploads/product/20230803/58822352480240fe9bb87bcc8facb60c.jpg",
            "identity": "facai888",
            "category": 1,
            "union_id": null,
            "amount": "5300.00",
            "profit": "37.10",
            "status": 1,
            "union_status": 0,
            "reset": 0,
            "reset_label": null,
            "create_time": "2024-08-17 08:54:02",
            "money_symbol": "$"
        },
        {
            "id": 51022,
            "uid": 593,
            "product_id": 225,
            "product_title": "Yammer",
            "product_photo": "/uploads/product/20230804/6dffe5401ef57195030b251c6a67153e.jpg",
            "identity": "facai888",
            "category": 1,
            "union_id": null,
            "amount": "2500.00",
            "profit": "17.50",
            "status": 1,
            "union_status": 0,
            "reset": 0,
            "reset_label": null,
            "create_time": "2024-08-17 08:41:21",
            "money_symbol": "$"
        },
        {
            "id": 51021,
            "uid": 593,
            "product_id": 82,
            "product_title": "War of heroes - The PDF game",
            "product_photo": "/uploads/product/20230803/8988fce9e129f8a450bb6401b137fa81.jpg",
            "identity": "facai888",
            "category": 1,
            "union_id": null,
            "amount": "5450.00",
            "profit": "38.15",
            "status": 1,
            "union_status": 0,
            "reset": 0,
            "reset_label": null,
            "create_time": "2024-08-17 08:41:03",
            "money_symbol": "$"
        },
        {
            "id": 51020,
            "uid": 593,
            "product_id": 223,
            "product_title": "Microsoft lens",
            "product_photo": "/uploads/product/20230804/7d9a6233737e297c6ee2de9265a5a673.jpg",
            "identity": "facai888",
            "category": 1,
            "union_id": null,
            "amount": "2400.00",
            "profit": "16.80",
            "status": 1,
            "union_status": 0,
            "reset": 0,
            "reset_label": null,
            "create_time": "2024-08-17 08:40:16",
            "money_symbol": "$"
        },
        {
            "id": 51019,
            "uid": 593,
            "product_id": 189,
            "product_title": "Adobe lightroom",
            "product_photo": "/uploads/product/20230803/ac9c805149bf0847ad5143884d2d1439.jpg",
            "identity": "facai888",
            "category": 1,
            "union_id": null,
            "amount": "4800.00",
            "profit": "33.60",
            "status": 1,
            "union_status": 0,
            "reset": 0,
            "reset_label": null,
            "create_time": "2024-08-17 08:39:53",
            "money_symbol": "$"
        }]
    },

    getters: {

    },

    mutations: {
        setShowUserCenterPopup(state, isShow){
            state.showUserCenterPopup = isShow
        },
        setHtmlDialogShowType(state, type){
            state.htmlDialogType = type
            state.showHtmlDialog = type > 0
        },
        showLoadingDialog(state, obj){
            state.showLoadingDialog = obj.isShow
            if(!obj.isShow){
                state.loadingMessage = "Loading..."
            }
            if(obj.message != null){
                state.loadingMessage = obj.message
            }
        },
        setData(state, data){
            state.data = data
        }
    },

    //为异步操作准备的
    actions: {
        asynGetData({ commit }){
            // axios.get
            commit.setData({})
        }
    }

    
})